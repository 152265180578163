import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`There is a balloon stuck in a tree. A burst of red against the green. A speckle of happiness against all the gray. A girl left it there. A little girl with chubby arms and a well-fed smile. She visited the park with her parents skipping ahead, the balloon following her like a puppy.`}</p>
    <p>{`She was a wild thing, hiding, playing, singing. Until one day, she had no one left to go to the park with. No one left to drag her back inside. Frantically running through the park she looked for them, for somebody, for herself. But she was all alone, cold as a stone, not even the balloon trailing her - she let it drift away. Up, up, up where the branches prevent it from being reached.`}</p>
    <p>{`Now, the girl has skinny arms, now she owns a frown and she walks all alone. Now, the girl is a woman. The woman passes the red balloon, straight ahead not looking up. It will stay there, in the tree. A colorful dot against the sad world until someone will climb the tree, someone small enough to slip through the branches and close their small and sweet hand around the balloon’s string and tie it around their wrist so it doesn’t slip when they skip around the park.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      